import { useEffect } from 'react'

import i18n from 'i18next'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next, useTranslation } from 'react-i18next'

import { useLocalStorage } from '@ui/helpers/useStorage'

export const supportedLanguages = ['en', 'de', 'ja', 'fr', 'pt']

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    // languages, namespaces, resources
    languages: supportedLanguages,
    lng: 'en',
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: 'common',

    // plugin options
    cache: {
      enabled: true,
    },

    // react-i18next options
    react: {
      useSuspense: true,
    },

    // backend options
    backend: {
      // path where resources get loaded from (under public folder)
      // loadPath: '/locales/{{ns}}/{{lng}}.json',
      loadPath: (lang, namespace) => {
        return `/locales/${namespace[0]}/${lang[0]}.json`
      },
    },
  })

if (import.meta.hot) {
  import.meta.hot.on('locales-update', () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language)
    })
  })
}

export function useUILanguage() {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useLocalStorage(
    'language',
    i18n?.language || 'en'
  )

  useEffect(() => {
    if (language && i18n?.language && language !== i18n?.language) {
      i18n.changeLanguage(language)
    }
  }, [i18n, language])

  return { language: language || 'en', setLanguage }
}
