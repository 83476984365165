import React from 'react'

import { DialogContext, useDialogState } from './hooks'

/**
 * Dialog is a component that sets the context for a Dialog.
 * @param {Object} props Props object.
 * @param {React.ReactNode} props.children The content of the Dialog.
 * @param {Boolean} props.initialOpen If true, the Dialog will be open by default.
 * @param {Boolean} props.open If true, the Dialog will be open.
 * @param {Function} props.onOpenChange A function that will be called when the Dialog is opened or closed.
 * @returns {React.ReactNode} The Dialog component.
 */
export default function Dialog({ children, open, onOpenChange, initialOpen }) {
  const dialog = useDialogState({
    initialOpen,
    open,
    onOpenChange,
  })

  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  )
}
