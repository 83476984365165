/**
 * Conditional wrap component
 * @param {Object} props The component props
 * @param {React.ReactNode} props.children The component children
 * @param {Boolean} props.condition The component condition
 * @param {Function} props.ifRender Renders the children if the condition is `true`
 * @param {Function} props.elseRender Renders the children if the condition is `false`
 * @returns {React.ReactElement} The ConditionalWrap component
 */
export default function ConditionalWrap({
  children,
  condition,
  ifRender,
  elseRender,
}) {
  // If the condition is true, render the children
  if (condition) {
    // If the ifRender prop is a function, call it with the children
    return typeof ifRender === 'function' ? ifRender(children) : children
  }

  // If the elseRender prop is a function,
  if (typeof elseRender === 'function') {
    // call it with the children
    return elseRender(children)
  }

  // Otherwise, return null
  return null
}
