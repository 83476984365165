import { createContext, useContext } from 'react'

export const LocaleContext = createContext()

/**
 * Hook to access the locale context.
 * @returns {Object} The locale context.
 */
export function useLocaleContext() {
  return useContext(LocaleContext)
}
