import { useCallback, useMemo } from 'react'

import { useMatch } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { removeUndefined } from './objects'
import { isArray, isString } from './types'

/**
 * Checks if provided string starts with a protocol that matches a external url
 * Supports `http(s):`, `ftp(s):`, `mailto:`, `tel:` and `sms:`,
 * @param {String} value the value to check
 * @returns {Boolean} true if the value is an external url
 */
export function isExternalUrl(value) {
  return isString(value)
    ? /^(:\/\/)/.test(value) || // ://
        /^(f|ht)tps?:\/\//i.test(value) || // http(s) or ftp(s)
        /^(mailto|tel|sms):/i.test(value) // Email, Phone or SMS
    : false
}

/**
 * Given a react-router's route path it returns all the avaiable params in the current location url
 * Example:
 *  const { articleId } = useGlobalParams('/articles/:articleId')
 *
 * @param {String} path path to match
 * @returns {Object} params object
 */
export function useGlobalParams(path = '/') {
  const match = useMatch(path)

  if (!path) return {}

  return match?.params || {}
}

/**
 * @callback UseQuerySetParams The set params function
 * @param {Object} params The params object to set
 * @param {import('react-router').NavigateOptions} options The set params options
 * @returns {void}
 */

/**
 * A utility hook that wraps `useSearchParams` from React Router and provides a parsed params object and
 * a utility function for setting params.
 *
 * `params` is a parsed object which converts multiple instances of a key in the query string to an
 * array.
 *
 * For example, given the following searchParams (string): `page=1&module=articles&module=web`, `params`
 * will be parsed as:
 * ```
 * {
 *   page: '1',
 *   module: ['articles', 'web']
 * }
 * ```
 *
 * `setParams` appends the given object to the current `params`, replacing any existing keys with the
 * given new value and removing any keys with a value of `undefined`.
 *
 * Given the above example `params` object
 *
 * ```
 * setParams({ page: undefined, module: ['web'], query: 'Search' })
 * ```
 * returns:
 * ```
 * {
 *   module: ['web'],
 *   query: 'Search'
 * }
 * ```
 *
 * Usage example:
 * ```
 * const [params, setParams] = useQueryParams()
 * ```
 *
 * @returns {[Object, UseQuerySetParams]} An array containing the parsed params object and the setParams function
 */
export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = useMemo(
    () =>
      [...searchParams.entries()].reduce((acc, [key, value]) => {
        if (isArray(acc[key])) {
          return {
            ...acc,
            [key]: [...acc[key], value],
          }
        }
        if (acc[key]) {
          return {
            ...acc,
            [key]: [acc[key], value],
          }
        }
        return {
          ...acc,
          [key]: value,
        }
      }, {}),
    [searchParams]
  )

  /**
   * A utility function to set the search params in the URL.
   * @type {UseQuerySetParams} The set params function
   */
  const setParams = useCallback(
    (paramsObject = {}, options = {}) => {
      // If the object is empty, clear the search params
      if (!paramsObject || Object.keys(paramsObject).length === 0) {
        setSearchParams('', options)
        return
      }

      // Remove any keys with a value of `undefined`
      const validParams = removeUndefined(
        Object.entries(paramsObject).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          params
        )
      )

      // Update the search params with the new object
      setSearchParams(validParams, options)
    },
    [params, setSearchParams]
  )

  return [params, setParams]
}

/**
 * A RegExp to match a valid domain (or localhost) with an optional port number.
 */
export const domainRegex =
  /^(?!-)(?!.*--)(localhost|([a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})))?(:\d+)?$/

/**
 * A function to validate if the given string is a valid domain.
 *
 * @param {String} domain The domain to validate
 * @returns {Boolean} true if the domain is valid
 */
export function validateDomain(domain) {
  return domainRegex.test(domain)
}
