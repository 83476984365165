import React, { useMemo } from 'react'

import { useCookie } from '@ui/helpers/useStorage'

import { EntityContext } from './context'

/**
 * The entity provider component
 * @param {Object} params The component props
 * @param {React.ReactNode} params.children The children components
 * @param {String} params.defaultEntityId The default entity id
 * @returns {React.ReactElement} The entity provider component with the entity context
 */
export default function EntityProvider({ defaultEntityId, children }) {
  // Load the entity id from the cookies
  const [entityId, setEntityId] = useCookie('entityId', defaultEntityId)

  const value = useMemo(
    () => ({ entityId, setEntityId }),
    [entityId, setEntityId]
  )

  return (
    <EntityContext.Provider value={value}>{children}</EntityContext.Provider>
  )
}
