import React from 'react'

import { useMergeRefs } from '@floating-ui/react'

import { useDialogContext } from './hooks'

/**
 * Dia§logTrigger is a component that opens a Dialog when clicked.
 * It can be used as a button or as a wrapper for any element.
 *
 * @param {React.ReactNode} children The content of the trigger.
 * @param {Boolean} asChild  If true, the trigger will be rendered as a wrapper for the children.
 */
const DialogTrigger = React.forwardRef(function DialogTrigger(
  { children, asChild = false, ...props },
  propRef
) {
  const context = useDialogContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

export default DialogTrigger
